<template>
	
  <nav>
    
  </nav>
  <router-view/>
</template>

<style>

@import "/src/assets/tailwind.css";
*{
  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* For Mozilla Firefox */
::-moz-selection {
  background: #FF78FE;
  color: white;
}
::selection {
    background-color: #ff78fe;
    color: white;}
</style>
