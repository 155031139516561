<template>
	<div id="Acronym" class="background" data-scroll-container>
		<div class="headerbg">
			<div class="pls" data-scroll data-scroll-speed="2" data-scroll-delay="0.2">
				<div class="upper">
					<p class="pink text-reveal">ACRONYM</p>
					<p class="text-reveal">CREATIVE STUDIO</p>
					<p class="text-reveal">SLOVAKIA BASED</p>
				</div>
				<div class="under">
					<p class="text-reveal-pa">Vytvárame webové stránky, ktoré vás odlišia od vašich konkurentov.</p>
					<p class="text-reveal-pa">V Acronym máme vášeň pre tvorbu výnimočných webových riešení a zážitkov, ktoré pomáhajú podnikom rozkvitať v digitálnom svete. S našou odbornosťou a kreatívnym prístupom transformujeme nápady na pútavé webové stránky a používateľsky prívetivé dizajny, ktoré zanechávajú trvalý dojem.</p>
				</div>

				<div class="svg-container">
					<svg class="logo-reveal" xmlns="http://www.w3.org/2000/svg" width="678" height="295" viewBox="0 0 678 295" fill="none">
						<path d="M53.505 157.365C27.2929 157.365 18.1988 179.765 18.1988 200.642C18.1988 221.519 27.2929 243.919 53.505 243.919C75.8195 243.919 82.9598 228.478 83.8328 215.217H102.032C100.514 240.445 80.7979 259.141 53.505 259.141C21.2232 259.141 0 235.437 0 200.652C0 165.868 21.2232 142.153 53.505 142.153C80.7979 142.153 99.4228 160.422 102.032 185.211H83.8328C82.9598 173.014 75.5909 157.365 53.505 157.365Z" fill="black" />
						<path d="M137.546 145.179V167.141H137.982C143.834 152.576 152.71 142.132 168.528 142.132C172.644 142.132 175.679 142.351 178.714 142.789V160.182C176.115 159.743 174.161 159.524 171.563 159.524C151.202 159.524 137.556 175.612 137.556 196.927V256.074H120.23V145.179H137.546Z" fill="black" />
						<path d="M184.118 200.631C184.118 166.056 204.479 141.923 238.268 141.923C271.838 141.923 292.209 166.056 292.209 200.631C292.209 235.207 271.849 259.339 238.268 259.339C204.479 259.339 184.118 235.207 184.118 200.631ZM274.01 200.631C274.01 176.927 262.962 157.146 238.268 157.146C213.355 157.146 202.307 176.937 202.307 200.631C202.307 224.336 213.355 244.117 238.268 244.117C262.962 244.127 274.01 224.336 274.01 200.631Z" fill="black" />
						<path d="M328.378 145.179V158.439H328.815C334.666 150.614 346.359 142.132 365.202 142.132C385.344 142.132 402.244 153.223 402.244 178.669V256.084H384.907V183.02C384.907 168.664 378.193 157.365 360.868 157.365C341.37 157.365 328.368 169.759 328.368 186.932V256.084H311.042V145.179H328.378Z" fill="black" />
						<path d="M411.13 145.179H430.628L464.635 233.465H465.071L497.561 145.179H516.196L471.578 259.339C460.53 287.394 449.918 295 434.972 295C431.075 295 428.476 294.781 423.488 293.915V279.131C427.167 279.569 428.903 279.778 432.156 279.778C441.25 279.778 451.217 274.123 455.988 254.988L411.13 145.179Z" fill="black" />
						<path d="M529.396 145.179H546.721V158.439H547.158C553.01 150.614 562.54 142.132 579.222 142.132C595.03 142.132 604.134 148.872 608.676 161.267H609.113C616.7 150.833 628.174 142.132 644.856 142.132C667.17 142.132 678 155.393 678 178.669V256.084H660.664V183.02C660.664 168.664 657.847 157.365 640.522 157.365C623.185 157.365 612.356 169.759 612.356 186.932V256.084H595.03V183.02C595.03 168.664 592.213 157.365 574.888 157.365C557.562 157.365 546.721 169.759 546.721 186.932V256.084H529.396V145.179Z" fill="black" />
						<path
							d="M1.68515e-06 85.6778C1.68515e-06 60.2416 18.6249 53.4912 47.2274 48.0555C66.0705 44.5812 74.7386 42.4007 74.7386 31.5293C74.7386 22.8279 69.9785 15.2221 51.7797 15.2221C30.7643 15.2221 24.705 21.5238 23.6241 37.6223H5.41494C6.49585 16.3072 19.9241 0 52.4241 0C73.0029 0 91.6278 8.70133 91.6278 35.8799V89.1521C91.6278 97.8534 92.927 102.204 97.261 102.204C97.9054 102.204 98.5602 102.204 99.8593 101.985V114.161C97.0427 114.818 94.4444 115.246 91.4095 115.246C81.8788 115.246 76.0274 111.552 74.9465 97.8534H74.5099C67.3593 109.81 54.7938 117.207 36.3871 117.207C14.0726 117.207 1.68515e-06 104.374 1.68515e-06 85.6778ZM74.7282 71.3217V53.7103C71.4751 57.1846 60.6452 60.012 48.7344 62.4117C27.2929 66.9814 18.1884 71.979 18.1884 84.8119C18.1884 95.6833 23.8216 101.995 38.1228 101.995C58.9199 101.985 74.7282 92.6368 74.7282 71.3217Z"
							fill="black"
						/>
						<path d="M213.23 50.017V67.4092H116.717V50.017H213.23Z" fill="black" />
						<path
							d="M678 17.2983C678 27.1056 670.33 34.8053 660.352 34.8053C650.468 34.8053 642.59 27.1056 642.59 17.2983C642.59 7.69974 650.468 0 660.352 0C670.33 0 678 7.69974 678 17.2983ZM646.997 17.2983C646.997 24.9981 652.671 31.1224 660.446 31.1224C668.012 31.1224 673.583 24.9981 673.583 17.4131C673.583 9.71335 668.012 3.4847 660.342 3.4847C652.671 3.4847 646.997 9.70292 646.997 17.2983ZM657.608 26.3752H653.617V9.07692C655.187 8.76392 657.4 8.54483 660.227 8.54483C663.491 8.54483 664.956 9.07693 666.214 9.80726C667.16 10.548 667.898 11.9148 667.898 13.605C667.898 15.5038 666.422 16.9853 664.322 17.6113V17.82C666.006 18.4564 666.952 19.7188 667.482 22.035C668.002 24.6746 668.324 25.7284 668.74 26.3648H664.437C663.907 25.7284 663.584 24.153 663.065 22.1498C662.753 20.2509 661.693 19.4058 659.489 19.4058H657.598V26.3752H657.608ZM657.723 16.5576H659.614C661.818 16.5576 663.605 15.8168 663.605 14.0327C663.605 12.4469 662.452 11.3931 659.926 11.3931C658.887 11.3931 658.149 11.4974 657.723 11.6122V16.5576Z"
							fill="black"
						/>
					</svg>
				</div>
				<div class="scroll-indicator">
					<svg class="circular-chart" viewBox="0 0 36 36">
						<circle class="circle-bg" cx="18" cy="18" r="15.9155" fill="transparent" />
						<circle class="circle" :style="circleFillStyle" cx="18" cy="18" r="15.9155" fill="transparent" />
						<text :style="textStyle" x="18" y="20.35" class="percentage"></text>
					</svg>
				</div>
			</div>
		</div>
		<Banner></Banner>

		<div id="Team" class="three-column-layout">
			<div class="column1">
				<p class="tech">E-shop</p>
				<p class="description">Shopify - Popredná platforma na vytváranie a správu online obchodov pre našich klientov.</p>
			</div>

			<div class="column2">
				<p class="tech">Portfolio</p>
				<p class="description">Webflow a Vue.js - Moderné nástroje na vytváranie responzívnych portfólií a webových stránok</p>
			</div>
			<div class="column3">
				<p class="tech">Design</p>
				<p class="description">Figma a Canva - Kreatívne nástroje na dizajn a tvorbu vizuálneho obsahu pre našich klientov</p>
			</div>
		</div>
		<!-- <section id="sectionPin">
			<div class="pin-wrap" ref="pinWrap">
				<div class="sticky-container" ref="stickyContainer">
					<main>
						<div class="scrollable-content">
							<div class="blue-screen panel">
								<div class="centered-text">
									<h1>COURSE OF COOPERATION</h1>
									<p>
										We believe that cooperation is essential for <br />
										achieving collective goals, fostering innovation, <br />
										and creating harmonious environments.
									</p>
								</div>
							</div>

							<div class="red-screen panel">
								<div class="centered-text">
									<h1>strategy</h1>
									<p>
										Together we will define the goals we want to <br />
										achieve. We will answer your questions, plan the procedure for the <br />
										implementation of the project <br />
										and distribute tasks for individual teams.
									</p>
								</div>
							</div>

							<div class="green-screen panel">
								<div class="centered-text">
									<h1>delivery of documents</h1>
									<p>
										You provide us with the materials needed to <br />
										create your website, such as the company <br />
										name, logo and contact details. Tell us more <br />
										about your services and how you differ from the <br />
										competition.
									</p>
								</div>
							</div>

							<div class="purple-screen panel">
								<div class="centered-text">
									<h1>production</h1>
									<p>
										Having worked out the procedure and goals, it's <br />
										time to get down to work. Each task is handled <br />
										by experienced professionals, whether it is <br />
										programming, design or marketing.
									</p>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</section> -->
		<Banner2></Banner2>
		<div id="Projects">
			<Portfolio id="Projects" />
		</div>
		<Banner3></Banner3>
		<div id="Contact">
			<Contact></Contact>
		</div>
		<div></div>
	</div>

	<Nav @navigate="scrollToSection"></Nav>
</template>

<script>
import Banner from "../components/Banner.vue"
import Nav from "../components/Nav.vue"
import Cards from "../components/Cards.vue"
import Portfolio from "../components/Portfolio.vue"

import Contact from "../components/Contact.vue"
import Banner2 from "../components/Banner2.vue"
import abt from "../views/AboutView.vue"
import Zivnost from "../components/Zivnost.vue"

import Banner3 from "../components/Banner3.vue"
import { gsap } from "gsap"
import LocomotiveScroll from "locomotive-scroll"
import "locomotive-scroll/dist/locomotive-scroll.css"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import SplitType from "split-type"
export default {
	components: {
		Nav,
		abt,
		Banner,
		Cards,
		Portfolio,
		Zivnost,
		Contact,
		Banner2,
		Banner3,
	},

	data() {
		return {
			scroll: {
				progress: 0,
				message: "SCROLL TO EXPLORE",
			},
			isScreenWidthLessThan1000: false,

			locomotiveScrollInstance: true,
		}
	},
	watch: {
		$route() {
			this.refreshLocomotiveScroll() // Refresh Locomotive Scroll on route change
		},
	},

	mounted() {
		this.initLocomotiveScroll()
		this.initGSAPAnimations()
		this.initAnimation()
		gsap.registerPlugin(ScrollTrigger, MotionPathPlugin)
		this.checkScreenWidth()

		this.animateTextReveal()
		window.addEventListener("resize", this.checkScreenWidth)
		this.initScrollEffect()
	},
	mounted() {
		this.$nextTick(() => {
			this.locomotiveScrollInstance = new LocomotiveScroll({
				el: document.querySelector("[data-scroll-container]"),
				smooth: true,
			})

			new SplitType(".text-reveal")

			gsap.fromTo(
				".text-reveal-pa",
				{ opacity: 0 },
				{
					y: 0,
					opacity: 1,
					ease: "back.out",
					duration: 1.3,
				}
			),
				gsap.to(".char", {
					duration: 0.1,
					delay: 0.2,
					y: 0,

					stagger: 0.05,
				})
			gsap.from(".logo-reveal", {
				duration: 3,
				opacity: 0,
				y: 60,
				stagger: 2,
				ease: "elastic(1,0.3).out",
			})

			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.type === "childList") {
						this.locomotiveScrollInstance.update()
					}
				})
			})

			// const observer = new MutationObserver((mutations) => {
			// 	setTimeout(() => {
			// 		if (this.locomotiveScrollInstance) {
			// 			this.locomotiveScrollInstance.update()
			// 		}
			// 	}, 100) // Adjust delay as needed
			// })

			observer.observe(document.querySelector("[data-scroll-container]"), {
				childList: true,
				subtree: true,
			})

			this.locomotiveScrollInstance.on("scroll", (instance) => {
				this.handleScroll(instance)
			})

			this.locomotiveScrollInstance.update()
		})

		gsap.registerPlugin(ScrollTrigger)
	},

	beforeDestroy() {
		if (this.locomotiveScrollInstance) {
			this.locomotiveScrollInstance.destroy()
		}
		ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
		window.removeEventListener("resize", this.checkScreenWidth)
	},
	methods: {
		initAnimation() {
			gsap.registerPlugin(MotionPathPlugin)

			gsap.to(".animate-on-start", {
				duration: 5,
				ease: "power1.inOut",
				motionPath: {
					path: [
						{ x: 100, y: 0 },
						{ x: 200, y: -100 },
						{ x: 300, y: 0 },
						{ x: 400, y: -100 },
					],
					autoRotate: true,
				},
			})
		},
		checkScreenWidth() {
			this.isScreenWidthLessThan1000 = window.innerWidth < 1000
		},
		scrollToSection(sectionIndex) {
			const sectionMapping = {
				1: "Acronym",
				2: "Team",
				3: "Cooperation",
				4: "Projects",
				5: "Contact",
			}

			const sectionId = sectionMapping[sectionIndex] // Map index to ID
			const sectionElement = document.getElementById(sectionId)

			if (sectionElement && this.locomotiveScrollInstance) {
				this.locomotiveScrollInstance.scrollTo(sectionElement, {
					offset: 0, // Optional: Adjust if you need an offset
					duration: 800, // Duration of the scroll animation in milliseconds
					easing: [0.25, 0.0, 0.35, 1.0], // Optional: Easing function for the scroll animation
					disableLerp: true, // Optional: Disable Lerp effect if needed
				})
			}
		},

		handleScroll(instance) {
			const scrollPercent = (instance.scroll.y / instance.limit.y) * 100
			this.animateScroll(scrollPercent)
		},

		calculateTotalWidth() {
			const horizontalSections = document.querySelectorAll(".scrollable-content > div")
			return Array.from(horizontalSections).reduce((acc, el) => acc + el.offsetWidth, 0)
		},

		initLocomotiveScroll() {
			this.locomotiveScrollInstance = new LocomotiveScroll({
				el: this.$refs.stickyContainer,
				smooth: true,
				smartphone: {
					smooth: true,
				},
				tablet: {
					smooth: true,
				},
			})

			this.locomotiveScrollInstance.on("scroll", (instance) => {
				const scrollPercent = (instance.scroll.y / instance.limit.y) * 100
				this.animateScroll(scrollPercent)
			})
		},

		animateScroll(scrollPercent) {
			gsap.to(this.scroll, {
				progress: scrollPercent,
				duration: 0.5,
				onUpdate: () => {
					this.scroll.message = `SCROLL TO EXPLORE ${(100 - this.scroll.progress).toFixed(0)}%`
				},
			})
		},
		initGSAPAnimations() {
			gsap.registerPlugin(ScrollTrigger)

			this.$nextTick(() => {
				const horizontalSections = gsap.utils.toArray(".panel")
				gsap.to(horizontalSections, {
					xPercent: -100 * (horizontalSections.length - 1),
					ease: "none",
					scrollTrigger: {
						trigger: ".scrollable-content",
						pin: true,
						scrub: 1,
						end: () => `+=${document.querySelector(".pin-wrap").offsetWidth}`,
						invalidateOnRefresh: true,
					},
				})
			})
		},
	},
	computed: {
		circleFillStyle() {
			const circumference = 2 * Math.PI * 15.9155
			const offset = ((100 - this.scroll.progress) / 100) * circumference
			return {
				strokeDasharray: `${circumference} ${circumference}`,
				strokeDashoffset: offset.toString(),
			}
		},
		textStyle() {
			return {
				// The rotation should be a single expression enclosed in backticks
				transform: `rotate(${this.scroll.progress * 3.6 - 90}deg)`,
			}
		},
	},
}
</script>

<style scoped>
::selection {
	background-color: #ff78fe; /* Set the background color when text is selected */
	color: white; /* Set the text color when text is selected */
}
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}

:deep(.char) {
	/* Your styles for .char here */
	transform: translateY(115px);
	transition: transform 0.5s, opacity 0.5s;
}
p {
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.text-reveal-pa {
	opacity: 0;
	transition: opacity 2s ease-in-out;
}

.horizontal-container {
	overscroll-behavior: none;
	width: 600%;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;
}

.pin-wrap {
	display: flex;
	flex-direction: row;
	width: auto; /* Ensure this is wide enough to contain all children */
}
.scrollable-content {
	display: flex;
	width: auto; /* Adjust based on content */
}

.blue-screen,
.red-screen,
.green-screen,
.purple-screen {
	width: 100vw; /* or adjust as needed */
	height: 100vh;
	flex-shrink: 0;
}

.sticky-container {
	display: flex;
	overflow-x: auto;
	width: 100%;
	height: 100%;
}

.horizontal-section {
	display: flex;
	overflow-x: auto;
}

.description {
	font-size: 20px;
	display: flex; /* Enable flexbox */
	flex-direction: column; /* Stack items vertically */
	justify-content: start; /* Center items vertically */
	margin-top: 10px;
	height: screen;
	align-items: center; /* start items horizontally */
	text-align: start; /* Center text horizontally */
}

.content-1,
.content-2,
.content-3 {
	width: 50vw;
	height: 100vh;
	float: left;
	border: 1px solid red;
}
.pink {
	color: #ff78fe;
}

.red-screen {
	background: #ff6f50;
	width: 100%;
	height: 960px;
	margin-bottom: -0.5cm;
	flex-shrink: 0;
	padding: 20px;
	display: flex;
	float: left;
	justify-content: center;
	align-items: center;
}
.horizontal-scroll-section {
	display: flex;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch;
}

.horizontal-page {
	flex: none;
	width: 100vw;
	height: 100vh;
	scroll-snap-align: start;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2em;
}
.green-screen {
	background: #cbff5c;
	width: 100%;
	height: 960px;
	margin-bottom: -0.5cm;
	flex-shrink: 0;
	float: left;

	padding: 20px;
	display: flex;
	justify-content: center; /* Centers horizontally */
	align-items: center; /* Centers vertically */
	place-content: center;
}

.purple-screen {
	background: #b378ff;
	width: 100%;
	height: 960px;
	float: left;
	margin-bottom: -0.5cm;
	flex-shrink: 0;

	padding: 20px;
	display: flex;
	justify-content: center; /* Centers horizontally */
	align-items: center; /* Centers vertically */
	place-content: center;
}
.blue-screen {
	background: #78beff;
	width: 100%;
	height: 960px;
	margin-bottom: -0.5cm;
	float: left;
	flex-shrink: 0;

	padding: 20px;
	display: flex;
	justify-content: center; /* Centers horizontally */
	align-items: center; /* Centers vertically */
	place-content: center;
}
.centered-text {
	place-content: center;
	justify-content: center;
	width: 100%;
}
.centered-text h1 {
	display: flex;
	justify-content: center;
	align-items: center;

	place-content: center;
	height: 100%;
	width: 100%;
	color: #000;
	font-family: PP Neue Montreal;
	font-size: 62px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: uppercase;
}
.centered-text p {
	display: flex;
	margin-top: 30px;
	justify-content: center;
	place-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	color: #000;
	text-align: center;
	font-family: PP Neue Montreal;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

@media (min-width: 1000px) {
	.small {
		color: rgb(0, 0, 0);
		font-family: PP Neue Montreal;
		font-size: 20px;
		display: flex;
		place-items: end;
		justify-items: end;
		height: 399px;
		align-self: end;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
	}

	.three-column-layout {
		display: flex;
		height: 599px;

		flex-wrap: wrap; /* Allows columns to wrap onto the next line on smaller screens */
		justify-content: space-between; /* Distributes spacing evenly between columns */
		margin: 0 -10px; /* Adjust margin to handle spacing between columns */
	}
	.column1 {
		background: #b378ff;
	}
	.column2 {
		background: #cbff5c;
	}
	.column3 {
		background: #ff7050;
	}

	.column1,
	.column2,
	.column3 {
		padding: 20px; /* Adds padding inside each column */
		flex: 1; /* Allows each column to grow and fill the available space */
		height: 100%; /* Ensure the columns take up the full height of the container */
		font-size: 62px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		font-family: PP Neue Montreal;
	}
}

@media (max-width: 1500px) and (min-width: 1000px) {
	.small {
		color: rgb(0, 0, 0);
		font-family: PP Neue Montreal;
		font-size: 20px;
		display: flex;
		place-items: end;
		justify-items: end;
		height: 350px;
		align-self: end;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
	}

	.column1,
	.column2,
	.column3 {
		padding: 20px; /* Adds padding inside each column */
		flex: 1; /* Allows each column to grow and fill the available space */
		height: 100%; /* Ensure the columns take up the full height of the container */
		font-size: 52px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		font-family: PP Neue Montreal;
	}
}

@media (max-width: 1000px) {
	.three-column-layout {
		flex-direction: column; /* Stack columns vertically */
	}
	.column1 {
		background: #b378ff;
	}
	.column2 {
		background: #cbff5c;
	}
	.column3 {
		background: #ff7050;
	}
	.tech {
		font-size: 70px;
	}
	.three-column-layout > div {
		height: 100vh; /* Make each column take up the full viewport height */
		width: 100%; /* Ensure each column takes up the full width */
		display: flex; /* Enable flexbox for internal alignment */
		flex-direction: column; /* Stack internal content vertically */
		justify-content: center; /* Center content vertically for description */
		align-items: flex-start; /* Align items to the start (left) for 'small' text */
		align-self: end;
		font-family: PP Neue Montreal;
		padding: 20px;
	}
	.column1,
	.column2,
	.column3 {
		display: flex; /* Enable flexbox */
		flex-direction: column; /* Stack items vertically */
		justify-content: center; /* Center items vertically */

		align-items: center; /* Center items horizontally */
		text-align: center; /* Center text horizontally */
		height: 100%; /* Make sure columns are full height */
		/* Other existing styles... */
	}
	.description {
		margin-top: auto; /* Push description towards the center */
		margin-bottom: auto; /* Maintain centering */
		font-size: 33px;

		text-align: start; /* Center the text horizontally */
	}

	.small {
		margin-top: auto; /* Push 'small' text to the bottom */
		width: 100%; /* Full width to respect padding */
		text-align: left; /* Align 'small' text to the left */
		align-self: end;
		font-family: PP Neue Montreal;
		font-size: 20px;

		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
	}
}

.space {
	margin-top: 30px;
}

@media (max-width: 1000px) {
	.headerbg {
		background: black;
		height: 100vh;
		width: screen;
	}

	.centered-text h1 {
		display: flex;
		justify-content: center;
		align-items: center;
		place-content: center;
		justify-items: center;
		color: #000;
		text-align: center;
		font-family: PP Neue Montreal;
		font-size: 48px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		height: 100%;
		width: 100%;
		color: #000;
		font-family: PP Neue Montreal;
		font-size: 48px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
	}
	.centered-text p {
		display: flex;
		justify-content: center;
		place-content: center;
		align-items: center;

		width: 100%;
		color: #000;
		text-align: center;
		font-family: PP Neue Montreal;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.under {
		padding-top: 16px;
		height: auto;
		padding-bottom: 16px;

		border-top: 1px solid white;
		color: white;
		font-family: PP Neue Montreal;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding-right: 16px;
		padding-left: 16px;
		border-bottom: 1px solid white;
	}

	.upper {
		padding: 30px;
		color: white;
		font-family: PP Neue Montreal;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		padding-right: 16px;
		padding-left: 16px;
		line-height: normal;
		text-transform: uppercase;
	}

	svg {
		margin-top: 10px;
		width: 85%;
		margin-left: 16px;
		margin-right: 16px;
		height: 40vh;
		filter: invert(100%);
	}
}

@media (min-width: 1000px) {
	.headerbg {
		background: black;
		height: 100vh;
		width: screen;
	}

	.under {
		width: 35%;
		height: 100vh;
		border-right: 1px solid white;
		border-top: 1px solid white;
		padding-top: 30px;
		color: white;
		font-family: PP Neue Montreal;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;

		line-height: normal;
		padding-right: 30px;
		padding-left: 30px;
	}
	.svg-container {
		width: 33%;
		justify-content: right;
		align-items: right;
	}

	svg {
		width: 35%;
		position: absolute;
		top: 300px;
		right: 200px;
		filter: invert(100%);

		height: auto;
	}

	.upper {
		width: 35%;
		padding: 30px;
		color: white;
		font-family: PP Neue Montreal;
		font-size: 20px;
		padding-right: 30px;
		padding-left: 30px;

		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		border-right: 1px solid white;
	}
}

@media (max-width: 340px) and (min-width: 40px) {
	.tech {
		font-size: 50px;
	}

	.description {
		font-size: 32px;
	}
	.under {
		font-size: 16px;
	}
}
</style>
