<template >
	<div class="invoice-container">
	  <h1>Fakturačné údaje</h1>
	  <h2>Alexander Jakub Moravčík</h2>
	  <h2>ACRONYM</h2>
  
	  <div class="invoice-details">
		<div class="column">
		  <p><strong>Adresa:</strong></p>
		  <p>Pionierska 338/20<br />92701 Šaľa<br />Slovenská republika</p>
		  <p>IČO: 56074280</p>
		  <p>DIČ: 1129331236</p>

		</div>
		<div class="column">
		  <p><strong>Kontakt:</strong></p>
		  <p><br />Email: moravcik@acronym.sk<br />Web: <a href="http://acronym.sk" target="_blank">acronym.sk</a></p>
		</div>
		<div class="column">
		  <p><strong>Banka:</strong> Tatra banka, a.s.</p>
		  <p>Číslo účtu: 2931294712 / 1100<br />IBAN: SK22110000000002931294712<br />BIC: TATRSKBX</p>
		  <p>Variabilný symbol: 528024002<br />Konštantný symbol: 0008<br /></p>
		</div>
	  </div>
	</div>
	<div class="bottom-nav">
		<div class="blur-bg"></div>
		<div v-for="link in links" :key="link.id" class="nav-item" :class="{ active: activeLink === link.id }" @click="handleNavClick(link)">
			{{ link.label }}
		</div>
	</div>
  </template>
  
  <script>
  export default {
	name: "InvoiceDetails",

	data() {
		return {
			activeLink: null,
			links: [
				{ id: 1, label: "Close" },
			],
		}
	},
	methods: {
		initLocomotiveScroll() {
			this.scroll = new LocomotiveScroll({
				el: document.querySelector(".scroll-container"),
				smooth: true,
			})
		},
		handleNavClick(link) {
			if (link.label === "Visit site" && link.url) {
				window.open(link.url, "_blank") // Opens the link in a new tab
			} else if (link.label === "Close") {
				window.history.back() // Takes the user back in their browser history
			}
		},
	},
	beforeDestroy() {
		if (this.scroll) this.scroll.destroy()
	},
  }
  </script>
  
  <style scoped>
  * {
	font-family: 'PP Neue Montreal', sans-serif;
  }
  .bottom-nav {
	border-radius: 69px;
}
@media (min-width: 1000px) {

  .invoice-container {
	background: #e4ff78;
	padding: 30px;
	border-radius: 15px;
	height: 100vh;
	width: 100%;
	margin: auto;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
  }}
  h1 {
	font-size: 2em;
	margin-bottom: 0.5em;
  }
  h2 {
	font-size: 1.5em;
	margin-bottom: 0.5em;
	color: #333;
	
  }
  .invoice-details {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	gap: 20px;
  }
  .column {
	flex: 1;
	min-width: 250px;
	padding: 20px;
	border-radius: 10px;
	background: white;
	border: 1px solid #ddd;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	position: relative;
	overflow: hidden;
  }
  .column::before {

  }
  .column strong {
	font-size: 1.2em;
	color: #333;
  }
  p {
	margin: 0.5em 0;
	color: #555;
  }
  a {
	color: #007bff;
	text-decoration: none;
  }
  a:hover {
	text-decoration: underline;
  }

  @media (max-width: 1000px) {

	.invoice-container {
	background: #e4ff78;
	padding: 30px;
	height:100% ;

	width: 100%;
	margin: auto;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
  }
  .column {
	flex: 1;
	min-width: 250px;
	padding: 20px;
	border-radius: 10px;
	background: white;
	border: 1px solid #ddd;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	position: relative;
	overflow: hidden;
  }
	.bottom-nav {
		position: fixed;
		bottom: 0.5cm;
		left: 50%;

		transform: translateX(-50%);
		display: flex;
		justify-content: space-between;
		width: auto;
		border-radius: 30px;
		padding: 10px;
		background: rgba(0, 0, 0, 0.13);
		backdrop-filter: blur(17.5px);
	}
	.nav-item:hover {
		border: solid 1px rgb(255, 255, 255);
		border-radius: 69px;
		background-color: transparent;
		transition: transform 0.3s;
		transform: scale(1.05); /* Scale up effect on hover */
		border-color: rgb(255, 255, 255);
	}
	.nav-item {
		cursor: pointer;
		padding: 14px 10px;

		background: transparent;
		font-family: "PP Neue Montreal";
		font-size: 13px;

		color: white;

		border: solid 1px transparent;
		transition: all 0.3s ease; /* Transition for all properties */
	}

	.nav-item:hover {
		transform: scale(1.05); /* Scale up effect on hover */
		border-color: rgb(255, 255, 255);
		background-color: transparent;
	}

	.nav-item:active {
		transform: scale(0.95); /* Scale down effect on active */
		background-color: rgba(255, 255, 255, 0.2); /* Subtle color change on active */
	}

	.nav-item.active {
		border-color: rgb(255, 255, 255);
		border-radius: 69px;
		transition: background-color 0.5s, transform 0.3s; /* Longer transition for background-color */
	}

	.blur-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		backdrop-filter: blur(100px);
	}


	
}


@media (min-width: 1000px) {

	.bottom-nav {
		position: fixed;
		bottom: 1cm;
		left: 50%;

		transform: translateX(-50%);
		display: flex;
		justify-content: space-between;
		width: auto;
		border-radius: 30px;
		padding: 10px;
		background: rgba(0, 0, 0, 0.13);
		backdrop-filter: blur(17.5px);
	}
	.nav-item:hover {
		border: solid 1px rgb(255, 255, 255);
		border-radius: 69px;
		background-color: transparent;
		transition: transform 0.3s;
		transform: scale(1.05); /* Scale up effect on hover */
		border-color: rgb(255, 255, 255);
	}
	.nav-item {
		cursor: pointer;
		padding: 8px 12px;
		background: transparent;
		font-family: "PP Neue Montreal";
		font-size: 20px;
		color: white;

		border: solid 1px transparent;
		transition: all 0.3s ease; /* Transition for all properties */
	}

	.nav-item:hover {
		transform: scale(1.05); /* Scale up effect on hover */
		border-color: rgb(255, 255, 255);
		background-color: transparent;
	}

	.nav-item:active {
		transform: scale(0.95); /* Scale down effect on active */
		background-color: rgba(255, 255, 255, 0.2); /* Subtle color change on active */
	}

	.nav-item.active {
		border-color: rgb(255, 255, 255);
		border-radius: 69px;
		transition: background-color 0.5s, transform 0.3s; /* Longer transition for background-color */
	}

	.blur-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		backdrop-filter: blur(100px);
	}



}

  </style>
  