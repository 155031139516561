import { createRouter, createWebHashHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"
import AboutSupplements from "../views/AboutSupplements.vue"
import AboutDronik from "../views/AboutDronik.vue"
import AboutBeckov from "../views/AboutBeckov.vue"
import AboutSagrop from "../views/AboutSagrop.vue"

import error from "../components/404.vue"
import ZivnostVue from "../components/Zivnost.vue"

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{ path: "/sagrop", component: AboutSagrop },
	{ path: "/dronik", component: AboutDronik },
	{ path: "/beckov", component: AboutBeckov },

	{ path: "/supplements", component: AboutSupplements },
	{ path: "/:pathMatch(.*)*", name: "not-found", component: error },
	{ path: "/zivnost", component: ZivnostVue },

	{
		path: "/about",
		name: "about",

		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
		},
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router
